import React, {useState} from "react";
import { useSelector } from 'react-redux';

import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MenuItem from '@material-ui/core/MenuItem';
import { AddButton } from '../../UI/UIAddButton';

//import { usePLDR } from '../../../hooks/usePLD_Recursos'

export const Recursos = ( { data, valid }) => {
    const { tiposRecursos } = useSelector( state => state.general );
    const [tipoRecurso, setTipoRecurso] = useState(0);
    const [files, setFiles] = useState(null);

    const loadFile = e => {
        setFiles(e);
    }

    const insertFile = async() => {
        const f = new FormData();

        for (let index = 0; index < files.length; index++){
            f.append("files", files[index]);
        } 
    }

    return (
        <Grid container style={{marginTop:0}} >
            <Grid item xs={12} md={2} className="margenesGrid">
                Tipo/Recurso
                <TextField size='small'
                           select
                           fullWidth
                           value={tipoRecurso}
                           onChange={ e => setTipoRecurso( e.target.value )}>
                    {tiposRecursos.map((option) => (
                        <MenuItem key={option.value} 
                                  value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))} 
                </TextField>
            </Grid>
            {   ( tipoRecurso == 1 
                ? <>
                    <Grid item xs={12} md={9} className="margenesGrid">
                        Link a Cargar
                        <TextField size='small'
                                   fullWidth>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={1} className="margenesGrid">
                        <AddButton functionAdd={()=>{}}/>
                    </Grid>
                  </>
                : tipoRecurso == 2
                ? <>
                    <Grid item xs={12} md={8} className="margenesGrid">
                        Imagenes a Cargar
                        <TextField size='small'
                                   disabled={true}
                                   fullWidth>
                        </TextField>
                        <input type="file" id="inputFiles" names="files" multiple onChange={(e) => loadFile(e.target.files) } hidden/>
                    </Grid>
                    <Grid item xs={6} md={1} className="margenesGrid">
                        <button><label htmlFor="inputFiles" id="labelFile">...</label></button>
                    </Grid>
                    <Grid item xs={6} md={1} className="margenesGrid">
                        <AddButton functionAdd={()=>{}}/>
                    </Grid>
                  </>: "")
            }
        </Grid>
    );
}