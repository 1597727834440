import React, { useState } from 'react';
//import MaterialTable from 'material-table';
import MaterialTable from '@material-table/core';
import Chip from '@material-ui/core/Chip';

import ConfirmDialog from '../UI/ConfirmDialog'
import { NuevaPlanificacionMensual } from "./Mensual/NuevaPlanificacionMensual"
import { NuevaPlanificacionSemanal } from "./Semanal/NuevaPlanificacionSemanal"
import { NuevaPlanificacionDiaria } from "./Diaria/NuevaPlanificacionDiaria"

import { usePlanificacion } from '../../hooks/usePlanificacion';
import { getImprimirPlanificacionMensual,
         getImprimirPlanificacionSemanal,
         getImprimirPlanificacionDiario } from '../../servicios/planificacionService'
import { VisorReportes } from '../reportes/VisorReportes'

export const Planificacion = ( props ) => {
    const [confirmOpen, setConfirmOpen] = useState( false );
    const { unidadesDidacticas, altoContenedor, 
            openModalPlani, openModalPlaniSemanal, openModalPlaniDiaria,
            UpdateOpenModalPlani, UpdateOpenModalPlaniSemanal, UpdateOpenModalPlaniDiaria,
            CargarActa, CargarActaPlaniSemanal, CargarActaPlaniDiaria, 
            CargarVstUnidadDidactica, docente, 
            CerrarActaPM, CerrarActaPS, CerrarActaPD } = usePlanificacion();
    const [actaCerrar, setActaCerrar] = useState( 0 );
    const [tipoActaCerrar, setTipoActaCerrar] = useState( '' );
    const [urlReporte, setUrlReporte] = useState("");
    const [verReporte, setVerReporte] = useState(false);

    const NuevaUnidadDidactica = () => {
        UpdateOpenModalPlani(true);
    } 
    const EditActa = ( rowData ) => {
        if ( rowData.tipo === 'UD')
            CargarActa( rowData );

        if ( rowData.tipo === 'PS')
            CargarActaPlaniSemanal( rowData );
        
        if ( rowData.tipo === 'PD')
            CargarActaPlaniDiaria( rowData );
    }
    const CerrarActa = ( rowData ) => {
        setTipoActaCerrar( rowData.tipo );
        setActaCerrar( rowData.id );
        setConfirmOpen( true )    
    }

    const AplicarCierreActa = async () => {
        if ( actaCerrar > 0) {
            if ( tipoActaCerrar === 'UD')
                CerrarActaPM( actaCerrar );

            if ( tipoActaCerrar === 'PS')
                CerrarActaPS( actaCerrar );
        
            if ( tipoActaCerrar === 'PD')
                CerrarActaPD( actaCerrar );
        }
    }
    const Imprimir = async (row) => {
        setVerReporte(true)

        if ( row.tipo === 'UD') {
            const data = await getImprimirPlanificacionMensual(row.id);
        
            if (data.status === 200)
                setUrlReporte( `data:application/pdf;base64,${data.data}` );
         }
         if ( row.tipo == 'PS') {
            const data = await getImprimirPlanificacionSemanal(row.id);
        
            if (data.status === 200)
                setUrlReporte( `data:application/pdf;base64,${data.data}` );
        }
        if ( row.tipo == 'PD') {
            const data = await getImprimirPlanificacionDiario(row.id);
        
            if (data.status === 200)
                setUrlReporte( `data:application/pdf;base64,${data.data}` );
        }
    }
    const PlanificacionSemanal = ( data ) => {
        UpdateOpenModalPlaniSemanal(true);
        CargarVstUnidadDidactica( data);
    }
    const PlanificacionDiaria = ( data ) => {
        UpdateOpenModalPlaniDiaria(true);
        CargarVstUnidadDidactica( data);
    }

    const Verificar = ( data ) => data.tipo === 'UD' && data.esActiva == true
    const VerificarPS = ( data ) => data.tipo === 'PS' && data.esActiva == true
    const VerificarEstado = ( data ) => data.esActiva == true

    const colorFondo = {UD: '#A04000', PD:'#1A5276', PS:'#1E8449'}
    const colorLetra = {UD: 'white', PD:'yellow', PS:'black'}

    const columns = [ { title: 'Tipo', field: 'tipo', width: 5,
                      render: rowData => <Chip label={rowData.tipo} 
                                               style= {{color:`${colorLetra[rowData.tipo]}`,    background:`${colorFondo[rowData.tipo]}`}}
                                               size="small" /> },
                      { title: 'Docente', field: 'docente', width: 300 },
                      { title: 'Grado', field: 'grado' , width: 150},
                      { title: 'Disciplina', field: 'disciplina', width: 100 },
                      { title: 'Periodo Programado', field: 'periodoProgramado', width: 50},
                    ];
    const Regresar = () => setVerReporte(false)
    const UD = unidadesDidacticas.filter(x => x.tipo === 'UD')
    return (
        <>
          {  verReporte 
          ? <VisorReportes title="Visor de reportes" 
                           urlReporte = { urlReporte } 
                           CargarTerminar = { () => {} }
                           Regresar={ Regresar }
                           verBotonRegresar = { true }/>
          : <MaterialTable
                title = "Listado Planificaciones"
                columns = { columns }
                data = {unidadesDidacticas}
                options = {{
                    filtering: true,
                    actionsColumnIndex: -1,
                    paginationType: 'stepped',
                    sorting: false,
                    padding: 'dense',
                    pageSize: 10,
                    pageSizeOptions: [10],
                    maxBodyHeight: `${ (altoContenedor * .71) }px`,
                    headerStyle: {
                        backgroundColor: '#01579b',
                        color: '#FFF'
                    }
                }}
                localization={{
                    header: {actionts: 'xx'},
                    toolbar: {
                        searchPlaceholder: 'Buscar',
                    },
                    body: {
                        emptyDataSourceMessage: 'No hay registros!',
                    }
                }}
                actions = {[
                    { icon: 'post_add_icon',
                        tooltip: 'Nueva Unidad Didactica',
                        isFreeAction: true,
                        onClick: () => NuevaUnidadDidactica() 
                    },
                    rowData => ({ icon: 'edit',
                                  tooltip: 'Actualizar Acta',
                                  hidden: !VerificarEstado(rowData),
                                  onClick: (event, rowData) => EditActa( rowData ) 
                                }),
                    rowData => ({ icon: 'addCircle',
                                  tooltip: 'Add Planificacion Semanal',
                                  hidden: !Verificar(rowData),
                                  onClick: (event, rowData) => PlanificacionSemanal( rowData ) 
                                }),
                    rowData => ({ icon: 'addCircleOutline',
                                  tooltip: 'Add Planificacion Diaria',
                                  hidden: !VerificarPS(rowData),
                                  onClick: (event, rowData) => PlanificacionDiaria( rowData ) 
                                }),
                    rowData => ({ icon: 'print',
                                tooltip: 'Imprimir Planificacion',
                                onClick: (event, rowData) => Imprimir( rowData ) 
                              }),
                    rowData => ({ icon: 'eventBusy',
                              tooltip: 'Cerrar Acta Planificacion',
                              hidden: !(rowData.esActiva && docente == 0),
                              onClick: (event, rowData) => CerrarActa( rowData ) 
                            }),
                    ]}/>
            }

            { openModalPlani && <NuevaPlanificacionMensual/> }

            { openModalPlaniSemanal && <NuevaPlanificacionSemanal/> }

            { openModalPlaniDiaria && <NuevaPlanificacionDiaria/> }

            <ConfirmDialog 
                title = { `¿Confirma Cerrar esta Acta de Planificacion?` }
                open = { confirmOpen } 
                setOpen = { setConfirmOpen } 
                onConfirm = { AplicarCierreActa } />
        </>
    )
}