import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom'

import { Logout } from '../../state/actions/auth';
import ConfirmDialog from './ConfirmDialog';

export const Navbar = () => {
    const { nomUsuario } = useSelector( state => state.auth );
    const dispatch = useDispatch();
    const [confirmOpen, setConfirmOpen] = useState( false );

    const handleLogout = () => {
        dispatch( Logout() );
    }

    return (
        <div className="navbar navbar-dark bg-dark mb-1 px-3">
            <span className="mx-3 navbar-brand font-italic font-weight-bold">
                WSIAF
            </span>
            <NavLink
                activeClassName="active"
                className="nav-item nav-link" 
                exact
                to="/calificaciones">
                Calificaciones
            </NavLink>
            <NavLink 
                activeClassName="active"
                className="nav-item nav-link" 
                exact
                to="/planificacion">
                Planificaciones
            </NavLink>
            <NavLink 
                activeClassName="active"
                className="nav-item nav-link" 
                exact
                to="/reportes">
                Reportes
            </NavLink> 
            
            <span className="text-light text-center">Usuario: { nomUsuario }
            <button className="ml-3 btn btn-outline-light"
                    title="Cerrar Sesión"
                    onClick={ () => setConfirmOpen( true ) }>
                <i className="fas fa-sign-out-alt"></i>
            </button>
            </span>
            <ConfirmDialog 
                    title = { `¿Confirma Cerrar Sesión en el Sistema?` }
                    open = { confirmOpen } 
                    setOpen = { setConfirmOpen }
                    onConfirm = { handleLogout } />
        </div>
    )
}
/*

<NavLink 
                activeClassName="active"
                className="nav-item nav-link" 
                exact
                to="/planificaciones">
                Evaluaciones
            </NavLink>

            

            


<NavLink 
                style={({ isActive }) => !isActive ? { color: '#fff', fontWeight: '900' }
                                                   : { color: '#fff', } }
                activeClassName="active"
                className="nav-item nav-link" 
                exact
                to="/calificaciones">
                CALIFICACIONES
            </NavLink>

        <NavLink
                activeClassName="active"
                className="nav-item nav-link" 
                exact
                to="/calificaciones">
                CALIFICACIONES
            </NavLink>
            
            <NavLink 
                activeClassName="active"
                className="nav-item nav-link" 
                exact
                to="/planificaciones">
                Evaluaciones
            </NavLink>

            <NavLink 
                activeClassName="active"
                className="nav-item nav-link" 
                exact
                to="/planificacion">
                Planificaciones
            </NavLink>

            <NavLink 
                activeClassName="active"
                className="nav-item nav-link" 
                exact
                to="/reportes">
                Reportes
            </NavLink> 
*/