import React, { useState } from 'react';
import Badge from '@material-ui/core/Badge';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { DialogTitle } from '../../comunes/DialogTitle';
import { AddDetallePlaniSemanal } from './AddDetallePlaniSemanal';
import { usePlanificacion } from '../../../hooks/usePlanificacionSemanal';
import { RevisionesUnidadDidactica } from '../RevisionesUnidadDidactica';
import ConfirmDialog from '../../UI/ConfirmDialog'
import { useSelector } from 'react-redux';
import '../style.css';

export const NuevaPlanificacionSemanal = () => {
    const [tapSeleccionada, setTapSeleccionada] = useState('agregar');
    const { UpdateOpenModalPlaniSemanal, openModalPlaniSemanal, RevisionesNoAplicadasLength, actaPlaniSemanal, formValues, handleInputChange, vstUnidadDidactica, UpdContenido, ValidarActa, handleSelectChange } = usePlanificacion(); 
    const { semanas } = useSelector( state => state.general );

    const { semana,  observaciones } = formValues;
    const [confirmOpen, setConfirmOpen] = useState(false);

    return (
        <Dialog onClose = { () => UpdateOpenModalPlaniSemanal(false) } 
                disableEscapeKeyDown
                aria-labelledby = "customized-dialog-title" 
                open = { openModalPlaniSemanal }
                fullWidth
                maxWidth = 'lg'>
            <DialogTitle id = "customized-dialog-title" 
                         onClose = { () => UpdateOpenModalPlaniSemanal(false) }>
                 Planificacion Semanal {`(${vstUnidadDidactica.disciplina}/${vstUnidadDidactica.periodoProgramado})`}
            </DialogTitle>
            <DialogContent dividers>
                <Grid container component="main" spacing={3}>
                    <CssBaseline />
                    <Grid item xs={12} md={2} className="margenesGrid">
                        <Grid item xs={12} className="margenesGrid" >
                            Semana
                        </Grid>
                        <TextField size='small'
                                   select
                                   fullWidth
                                   value={semana}
                                   name="semana"
                                   onChange={ e => handleInputChange( e )}>
                            {semanas.map((option) => (
                            <MenuItem key={option.value} 
                                      value={option.value}>
                                {option.label}
                            </MenuItem>
                            ))}
                        </TextField>

                    </Grid>
                    <Grid item xs={12} md={ actaPlaniSemanal.id === 0 ? 10 : 9} className="margenesGrid">
                        <Grid item xs={12} className="margenesGrid" >
                            Observaciones 
                        </Grid>
                        <TextField size='small'
                                   multiline
                                   minRows={1}
                                   inputProps={{ maxLength: 500 }}
                                   fullWidth
                                   value={observaciones}
                                   name="observaciones"
                                   onChange={ e => handleInputChange( e )}/>
                    </Grid>
                    { actaPlaniSemanal.id > 0 && 
                        <Grid item xs={12} md={1} className="margenesGrid">
                            <Tooltip title="Guardar cambios">
                                <Button variant="contained"
                                        fullWidth
                                        color="primary"
                                        onClick={ () => setConfirmOpen(true)} >
                                        <SaveIcon/>
                                </Button>
                            </Tooltip>
                        </Grid>
                    }

                    <Grid item xs={12} className="margenesGrid">
                            <Tabs value={tapSeleccionada} onChange={ (e, nv) => setTapSeleccionada(nv) }
                                style={{backgroundColor:'#7EC6FB'}} >
                                <Tab value="agregar"
                                    label="Agregar Contenido"
                                    wrapped/>
                                <Tab value="revisiones" 
                                    label={<Badge badgeContent={RevisionesNoAplicadasLength()}
                                                  color="secondary">
                                                Revisiones
                                            </Badge> } />
                            </Tabs>
                        { tapSeleccionada === 'agregar' 
                            ? <AddDetallePlaniSemanal Validar = {ValidarActa} data = {formValues}/>
                            : <RevisionesUnidadDidactica tipo = 'PS'/>
                        }
                    </Grid>
                </Grid>
                <ConfirmDialog 
                    title = {`¿Confirma Actualizar esta Planificacion Semanal?`} 
                    open = { confirmOpen } 
                    setOpen = { setConfirmOpen } 
                    onConfirm = { () => UpdContenido() } />
            </DialogContent>
        </Dialog>
    );
}