import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';

import { UIMeses } from '../../UI/UIMeses';
import { UIGrados } from '../../UI/UIGrados';
import { UIDocentes } from '../../UI/UIDocentes';
import { UIDiciplinas } from '../../UI/UIDiciplinas';
import { UIFamiliaValores } from '../../UI/UIFamiliaValores';
import { UIPrincipiosValores } from '../../UI/UIPrincipiosValores';

import { CatalogoBase } from '../CatalogoBase';
import { usePlanificacion } from '../../../hooks/usePlanificacionUnidadDidactica';
import ConfirmDialog from '../../UI/ConfirmDialog'

import '../style.css';

export const EncabezadoActaPlanificacionMensual = () => {
    const { NuevoIndicadorLogro, QuitarIndicadorLogro, ValidarActa, actaActiva, formValues, setEI, handleInputChange, disciplinas, indicadoresLogro,  ActualizarPlanificacionMensual} = usePlanificacion(); 

    const [confirmOpen, setConfirmOpen] = useState(false);
    const { docente, disciplina, fecha, fechaNoEPI, familiaValores, principiosValores, periodoProgramado} = formValues;

    return (
        <Grid container component="main">
            <Grid item xs={12} md={4} className="margenesGrid">
                <UIDocentes docente= { docente } 
                            actaActiva={actaActiva.id}
                            handleInputChange ={ handleInputChange }
                            esEditable = { true }/>
            </Grid>
            <Grid item xs={12} md={4} className="margenesGrid">
                <UIGrados setEI= { setEI } 
                          handleInputChange= { handleInputChange }
                          esEditable = { true }/>
            </Grid>
            <Grid item xs={12} md={4} className="margenesGrid">
                <UIDiciplinas disciplinas = {disciplinas}
                              actaActiva = {actaActiva.id}
                              disciplina= { disciplina } 
                              listaVisible = {false}
                              handleInputChange ={ handleInputChange }/>
            </Grid>
            <Grid item xs={12} md={2} className="margenesGrid">
                <UIMeses periodoProgramado = { periodoProgramado }
                         handleInputChange = { handleInputChange }/>
            </Grid>
            <Grid item xs={6} md={2} className="margenesGrid">
                <Grid item xs={12} className="margenesGrid" >
                    Fecha
                </Grid>
                <TextField size='small'
                            fullWidth
                            type='date'
                            value={fecha}
                            name="fecha"
                            onChange={ e => handleInputChange( e )}/>
            </Grid>
            <Grid item xs={12} md={3} className="margenesGrid">
                <UIFamiliaValores familiaValores = { familiaValores } 
                                  handleInputChange = { handleInputChange }/>
            </Grid>
            <Grid item xs={12} md={5} className="margenesGrid">
                <UIPrincipiosValores principiosValores = { principiosValores } 
                                     handleInputChange = { handleInputChange }/>
            </Grid> 
            <Grid item xs={6} md={2} className="margenesGrid">
                <Grid item xs={12} className="margenesGrid" >
                    Fecha No EPI
                </Grid>
                <TextField size='small'
                            fullWidth
                            value={fechaNoEPI}
                            name="fechaNoEPI"
                            onChange={ e => handleInputChange( e)}/>
            </Grid>
            { actaActiva.id > 0 && 
            <>
                <Grid item xs={6} md={1} className="margenesGrid">
                    <Tooltip title="Guardar cambios">
                        <Button variant="contained"
                                fullWidth 
                                color="primary"
                                onClick={ () => setConfirmOpen(true)} >
                                <SaveIcon/>
                        </Button>
                    </Tooltip>
                </Grid>
            </>
            }
            <Grid item xs={12} className="margenesGrid">
                <CatalogoBase Nuevo = {NuevoIndicadorLogro}
                              Valid = {ValidarActa}
                              Quitar = {QuitarIndicadorLogro}
                              data= {indicadoresLogro}
                              title='Indicadores de Logro'
                              titleValid = 'Indicador de Logro'/>
            </Grid>
            <ConfirmDialog 
                title = {`¿Confirma Actualizar esta Unidad Didactica?`} 
                open = { confirmOpen } 
                setOpen = { setConfirmOpen } 
                onConfirm = { () => ActualizarPlanificacionMensual() } />
        </Grid>
    );
}